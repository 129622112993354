import React, { useEffect } from 'react'
import '../styles/ProductListing.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react'
import FormControl from '@mui/material/FormControl';
import { useContext } from 'react'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Funnel, XCircle } from "@phosphor-icons/react"
import { CustomerContext } from '../context/CustomerContext'
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import Box from "@mui/joy/Box";
import { API_BASE_URL } from '../App'
import { useFilterContext } from '../context/FilterContext';

function CustBottomNav({ ...props }) {

  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const { setSelectedCategory, selectedCategory, searchText } = useContext(CustomerContext);
  // const pageSize = 25;
  // const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedPriceRange, setSelectedPriceRange] = useState();
  // const [productData, setProductData] = useState([]);
  const {products, productList,filters, loading,fetchProductData,setPageNumber, pageNumber, updateFilters} = useFilterContext();

  // const [costRange1, setCostRange1] = useState();
  // const [costRange2, setCostRange2] = useState();
  // const apiUrl = `https://dev-api.myhraki.com/web/productgen/api/v1/search?PageNumber=${pageNumber}&PageSize=${pageSize}&${selectedCategory ? '&category=' + selectedCategory : ''}`
  // const apiUrl = `${API_BASE_URL}/productgen/api/v1/search?PageNumber=${pageNumber}&PageSize=${pageSize}${selectedCategory ? '&category=' + selectedCategory : ''}${searchText ? '&text=' + searchText : ''}`;

  // const [loading, setLoading] = useState(false);

  // async function fetchProductData() {
  //   setLoading(true);
  //   try {


  //     const response = await fetch(apiUrl, {
  //       method: "GET",
  //       headers: {

  //       }
  //     })
  //     const data = await response.json();
  //     setProductData(data?.result);
  //     setLoading(false);
  //   }
  //   catch {
  //     setLoading(false);
  //     //console.log('Error')
  //   }
  // }


  const [categoryData, setCategoryData] = useState([])

  async function fetchCategoryData() {
    
    try {


      const response = await fetch(`${API_BASE_URL}/productgen/api/v1/category/All`, {
        method: "GET",
        headers: {

        }
      })
      const data = await response.json();
      setCategoryData(data?.result);
    }
    catch {
      //console.log('Error')
    }
  }

  useEffect(() => {
    fetchCategoryData();
  }, [])

  // useEffect(() => {
  //   fetchProductData();

  // }, [selectedCategory]);
  // async function fetchProductData() {
  //   setLoading(true);
  //   try {


  //     const response = await fetch(apiUrl, {
  //       method: "GET",
  //       headers: {

  //       }
  //     })
  //     const data = await response.json();
  //     setProductData(data?.result);
  //     setLoading(false);
  //   }
  //   catch {
  //     setLoading(false);
  //     //console.log('Error')
  //   }
  // }
  // //console.log(props.categoryData)
  const handleRadioChange = (event) => {
    const categoryId = event.target.value;
    // setSelectedCategory(categoryId);
    console.log(categoryId);
    setPageNumber(0);
    updateFilters({
      searchValue: filters.searchValue,
      category: categoryId,
      priceRange: filters.priceRange,
    });
    fetchProductData(filters.searchValue, categoryId, filters.priceRange, false);
  };

  const handlePriceRangeChange = (event) => {
    const selectedRange = event.target.value;
    setSelectedPriceRange(selectedRange);
    updateFilters({
      searchValue: filters.searchValue,
      priceRange: selectedRange,
      category:filters.category
    });
    console.log(filters);
    fetchProductData(filters.searchValue, filters.category, event.target.value, false);
  };
  useEffect(()=>{
    updateFilters({
      searchValue: filters.searchValue,
      priceRange: 0,
      category:filters.category
    });  },[])
  // useEffect(() => {
  //   fetchProductData();
  // }, [selectedCategory, selectedPriceRange, pageNumber]);



  return (
    <div>
      <Box className="bottom-nav" sx={{ display: { xs: 'block', md: 'none' } }}>
        <div style={{ display: "flex", justifyContent: "center", width: "90%", gap: "16px", alignItems: "center",textAlign:"center" }}>
          <Funnel style={{ width: "17.61px", height: "16.6px", color: "rgba(253, 42, 128, 1)" }} />
          <div onClick={openModal} className='bottom-sticky-button'>Filter</div>
        </div>
        <Modal open={isOpen} onClose={closeModal}>
          <ModalDialog
            aria-labelledby="nested-modal-title"
            aria-describedby="nested-modal-description"
            sx={(theme) => ({
              [theme.breakpoints.only("sm")]: {
                top: "unset",
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: 0,
                transform: "none",
                maxWidth: "unset",
                overflow: "scroll",
              },
              [theme.breakpoints.only("xs")]: {
                top: "unset",
                bottom: 0,
                left: 0,
                right: 0,
                borderRadius: 0,
                transform: "none",
                maxWidth: "unset",
                overflow: "scroll",
              },
              // padding: '24px',
              borderRadius: 16,
            })}
          >
            <Box
              sx={{
                mt: 1,
                display: "flex",
                gap: 1,
                width: { xs: "300px" },
                flexDirection: { xs: "column", sm: "column" },
                alignItems: "center",
                justifyContent: "space-center",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "0px auto", gap: "18px" }}>
                <div className='product-listing-filter-section-mobile'>
                  <div className='product-listing-filter-section-heading' style={{ display: "flex", alignItems: "center", gap: "8px", cursor: "pointer", position:'relative' }}>
                    <Funnel style={{ width: "25px", height: "25px", color: "rgba(253, 42, 128, 1)" }} />
                    Filters
                  </div> <XCircle onClick={closeModal} style={{ width: "2rem", height: "2rem" , position:'absolute', top:'1rem', right:'1rem' }} />
                </div>
                <Box style={{display:'flex',flexWrap:'wrap', justifyContent:'center', gap:'2rem', alignItems:'flex-start'}}>
                <Accordion sx={{ width: '280px', background: '#FFF2F6', marginBottom: '5px' }} defaultExpanded={false}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography style={{ fontSize: "16px", textTransform: "uppercase", color: "#000", textAlign: "left" }}>Categories</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="customAccordionDetails">
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        value={selectedCategory}
                        name='category'
                        onChange={handleRadioChange}
                      >
                        {categoryData?.map((category) => (
                          <FormControlLabel
                            key={category.categoryId}
                            value={category.categoryId}
                            control={<Radio style={{ color: "#FD2A80" }} />}
                            label={category.categoryName}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ width: '280px', background: '#FFF2F6', marginBottom: '5px', marginTop:'0px' }} defaultExpanded={true}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography style={{ fontSize: "16px", textTransform: "uppercase", color: "#000", textAlign: "left" }}>Price Range</Typography>
                  </AccordionSummary>
                  <AccordionDetails className="customAccordionDetails">
                  <FormControl component="fieldset">
          <RadioGroup
            aria-labelledby="demo-row-radio-buttons-group-label"
            value={selectedPriceRange}
            name='priceRange'
            onChange={handlePriceRangeChange}
          >
            <FormControlLabel
              value={500}
              control={<Radio style={{ color: "#FD2A80" }} />}
              label="Under Rs. 499"
            />
            <FormControlLabel
              value={1000}
              control={<Radio style={{ color: "#FD2A80" }} />}
              label="Under Rs. 999"
            />
            <FormControlLabel
              value={2000}
              control={<Radio style={{ color: "#FD2A80" }} />}
              label="Under Rs. 1,999"
            />
            <FormControlLabel
              value={3000}
              control={<Radio style={{ color: "#FD2A80" }} />}
              label="Under Rs. 2,999"
            />
            <FormControlLabel
              value={5000}
              control={<Radio style={{ color: "#FD2A80" }} />}
              label="Under Rs. 5,000"
            />
            <FormControlLabel
              value={10000}
              control={<Radio style={{ color: "#FD2A80" }} />}
              label="Under Rs. 10,000"
            />
            <FormControlLabel
              value={1000000000}
              control={<Radio style={{ color: "#FD2A80" }} />}
              label="Rs. 10,000 and above"
            />
          </RadioGroup>
        </FormControl>

                  </AccordionDetails>
                </Accordion>
                </Box>
              </div>
            </Box>
          </ModalDialog>
        </Modal>
      </Box>
    </div>
  )
}

export default CustBottomNav