import React, { useEffect, useState } from "react";
import "../styles/AdminDiscount.css"
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AdminDiscountCard from "../components/AdminDiscountCard";
import { TextField } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AdminLayoutAdjustment from "../components/AdminLayoutAdjustment";
import Heading from "../components/Heading";
import withAdminAuth from "../context/withAdminAuth";
import { useContext } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { AdminContext } from "../context/AdminContext";

function AdminDiscount() {
  const [status, setStatus] = useState(10);
  const { accessToken, sellerData } = useContext(AdminContext);
  const [sellerDiscountData, setSellerDiscountData] = useState([]);
  const [couponData, setCouponData] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [factAddLayout, setFactAddLayout] = useState(false);

  useEffect(() => {
    fetchCoupons();
  }, []);

  const handleStartDateChange = (event) => {
    const selectedStartDate = event.target.value;
    setSelectedStartDate(selectedStartDate);
    formik.handleChange(event);
  };
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1;
  let day = currentDate.getDate();

  if (month < 10) {
    month = `0${month}`;
  }
  if (day < 10) {
    day = `0${day}`;
  }

  const maxDate = `${year}-${month}-${day}`;
  const minDate = `${year}-${month}-${day}`;
  const [selectedStartDate, setSelectedStartDate] = useState(minDate);

  async function fetchCoupons() {
    try {
      const response = await fetch(
        "https://dev-api.myhraki.com/web/coupons/api/v1/GetAllCoupon",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.ok) {
        const coupons = await response.json();
        setSellerDiscountData(coupons.result);
      } else {
        console.error("Unable to fetch coupons:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  const validationSchema = yup.object({
    couponName: yup
      .string()
      .required("Coupon Name is required")
      .min(2, "couponName must be at least 2 characters")
      .max(30, "couponName can't be more than 30 characters")
      .trim(),
    couponCode: yup
      .string().trim()
      .required("Coupon Code is required")
      .min(2, "couponCode must be at least 2 characters")
      .max(19, "couponCode can't be more than 19 characters")
      .test(
        "no-blank-spaces",
        "couponCode cannot be just blank spaces",
        (value) => {
          return !(/^\s+$/.test(value)); // Check if the value contains only spaces
        }
      ),
    offerType: yup.string().required("Offer Type is required"),
    amount: yup
      .string()
      .required("Amount is required")
      .test("numeric", "Please enter a valid number", (value) => {
        return !isNaN(value);
      }),
    couponStartDateTime: yup.string().required("Coupon Start Date is required"),
    couponExpiryDateTime: yup.string().required("Coupon Expiry Date is required"),
    products: yup.string()
      .required("Product is required")
      .test(
        "no-blank-spaces",
        "couponCode cannot be just blank spaces",
        (value) => {
          return !(/^\s+$/.test(value));
        }),
    minAmountCart: yup
      .string()
      .required("Minimum Amount is required")
      .test("numeric", "Please enter a valid number", (value) => {
        return !isNaN(value);
      }),
    message: yup.string()
      .required("Message is required")
      .test(
        "no-blank-spaces",
        "couponCode cannot be just blank spaces",
        (value) => {
          return !(/^\s+$/.test(value)); // Check if the value contains only spaces
        }),
    maximumUse: yup
      .string().trim()
      .required("Maximum Usage is required")
      .test("numeric", "Please enter a valid number", (value) => {
        return !isNaN(value);
      }),

  });

  const formik = useFormik({
    initialValues: {
      couponName: "",
      couponCode: "",
      offerType: "",
      amount: "",
      couponStartDateTime: `${year}-${month}-${day}`,
      couponExpiryDateTime: "",
      products: "",
      minAmountCart: "",
      message: "",
      maximumUse: "",
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleFormSubmit(formik.values);
    },
  });

  const handleFormSubmit = async (event) => {

    try {
      const response = await fetch('https://dev-api.myhraki.com/web/coupons/api/v1/createCoupon', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json-patch+json",
        },
        body: JSON.stringify({
          "id": "500",
          "couponName": formik.values.couponName,
          "couponCode": formik.values.couponCode,
          "offerType": formik.values.offerType,
          "amount": formik.values.amount,
          "couponStartDateTime": formik.values.couponStartDateTime,
          "couponExpiryDateTime": formik.values.couponExpiryDateTime,
          "product": formik.values.products,
          "minAmountCart": formik.values.minAmountCart,
          "message": formik.values.message,
          "maximumUse": formik.values.maximumUse,
        }),
      });

      if (response.ok) {
        handleModalClose();
        fetchCoupons();
        formik.resetForm();
      } else {
        console.error('Failed to add new coupon:', response.status);
      }
    } catch (error) {
      console.error('Error adding new coupon:', error);
    }
  };

  const handleDeleteCoupon = async (id) => {
    try {
      const response = await fetch(
        `https://dev-api.myhraki.com/web/coupons/api/v1/DeleteCoupon?CouponId=${id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            // accept: "text/plain",
          },
        }
      );

      if (response.ok) {
        // Call fetchCoupons again after successful deletion
        await fetchCoupons();
      } else {
        const errorText = await response.text();
        throw new Error(errorText || "Failed to delete coupon");
      }
    } catch (error) {
      console.error("Error deleting coupon:", error);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const handleModalOpen = () => setOpenModal(true);
  const [warningOpenModal, setWarningOpenModal] = useState(false);
  const handleWarningModalOpen = () => setWarningOpenModal(true);
  const handleWarningModalClose = () => setWarningOpenModal(false);
  const [age, setAge] = React.useState('');
  const handleChangeDropDown = (event) => {
    formik.setFieldValue('offerType', event.target.value);
  };
  const closeModal = () => {
    setOpenModal(false)
    handleWarningModalClose();
  }
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  function addNewCoupon() {
    handleModalOpen();
  }
  useEffect(() => {
    fetchCoupons();
    setUpdated(false);
  }, [updated])

  return (
    <AdminLayoutAdjustment>
      <Heading text="Coupon" />
      <div className="layout-container">
        <div>
          <div className="seller-products-list-navbar">
            <div className="seller-products-list-search-and-filter">
              <button className="common-button" onClick={() => {
                setFactAddLayout(!factAddLayout);
              }}>
                + Add New Coupon
              </button>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ fontSize: "1.2rem", marginRight: "2rem" }}>
                  Status :{" "}
                </div>
                <div className="seller-products-list-category">
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth style={{ background: "white" }}>
                      <Select
                        style={{ background: "white" }}
                        value={status}
                        displayEmpty
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>All Coupons</MenuItem>
                        <MenuItem value={20}>Scheduled</MenuItem>
                        <MenuItem value={30}>Active</MenuItem>
                        <MenuItem value={40}>Expired</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </div>
              </div>
            </div>
          </div>

          {factAddLayout && (
            <div className="admin-discount-layout">
              <div className="discount-head-text">Add New Coupon</div>
              <form className="discount-page-form" onSubmit={formik.handleSubmit}>
                <div className="discount-form-field">
                  <div className="discount-field-name">Coupon Name: </div>
                  <TextField
                    autoFocus
                    autoComplete="off"
                    variant="outlined"
                    fullWidth
                    type="text"
                    id="address-fullname"
                    name="couponName"
                    className="discount-text-field"
                    placeholder=" "
                    value={formik.values.couponName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.couponName && Boolean(formik.errors.couponName)
                    }
                    helperText={formik.touched.couponName && formik.errors.couponName}
                  />
                </div>
                <div className="discount-form-field">
                  <div className="discount-field-name">Coupon Code: </div>
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    type="text"
                    id="address-street"
                    name="couponCode"
                    className="discount-text-field"
                    placeholder=" "
                    value={formik.values.couponCode}
                    onChange={formik.handleChange}
                    error={formik.touched.couponCode && Boolean(formik.errors.couponCode)}
                    helperText={formik.touched.couponCode && formik.errors.couponCode}
                  />
                </div>
                <div className="discount-form-field">
                    <div className="discount-field-name1">Offer Type: </div>
                    <div className="offer-type">
                    <FormControl>
                        <Select
                          value={formik.values.offerType}
                          onChange={handleChangeDropDown}
                          className="offer-dropdown"
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                        >
                          <MenuItem value="">
                            <em>Select Flat or Percentage</em>
                          </MenuItem>
                          <MenuItem value="Flat">Flat</MenuItem>
                          <MenuItem value="Percentage">Percentage</MenuItem>
                        </Select>
                        <FormHelperText>{formik.touched.offerType && formik.errors.offerType}</FormHelperText>
                      </FormControl>
                    </div>
                  </div>

                <div className="discount-form-field">
                  <div className="discount-field-name">Amount: </div>
                  <TextField
                    variant="outlined"
                    fullWidth
                    type="text"
                    id="address-country"
                    name="amount"
                    className="discount-text-field"
                    placeholder=" "
                    value={formik.values.amount}
                    // disabled
                    onChange={formik.handleChange}
                    error={formik.touched.amount && Boolean(formik.errors.amount)}
                    helperText={formik.touched.amount && formik.errors.amount}
                  />
                </div>
                <div className="discount-form-field">
                  <div className="discount-field-name">Start Date : </div>
                  <div className="start-end-date">
                    <input
                      variant="outlined"
                      // autoComplete="off"
                      fullWidth
                      type="date"
                      id="address-pincode"
                      name="couponStartDateTime"
                      className="calender"
                      placeholder="Enter date "
                      min={minDate}
                      // max={maxDate}
                      value={formik.values.couponStartDateTime}
                      onChange={(event) => {
                        formik.handleChange(event);
                        handleStartDateChange(event);
                      }}
                    />
                    <CalendarMonthIcon
                      className="discount-date-icon-admin"
                      fontSize="medium "
                    />
                    <FormHelperText>{formik.touched.couponStartDateTime && formik.errors.couponStartDateTime}</FormHelperText>

                  </div>
                </div>
                <div className="discount-form-field">
                  <div className="discount-field-name">Expiry Date : </div>
                  <div className="start-end-date">
                    <input
                      variant="outlined"
                      // autoComplete="off"
                      fullWidth
                      type="date"
                      id="address-pincode"
                      name="couponExpiryDateTime"
                      // className="discount-text-field"
                      placeholder=" "
                      min={selectedStartDate}
                      max={maxDate.selectedStartDate}
                      // min={minExpiryDate}
                      className="calender"
                      value={formik.values.couponExpiryDateTime}
                      onChange={(event) => {
                        formik.handleChange(event);
                        handleStartDateChange(event);
                      }}
                    />
                    <CalendarMonthIcon
                      className="discount-date-icon-admin"
                      fontSize="medium "
                    />
                    <FormHelperText>{formik.touched.couponExpiryDateTime && formik.errors.couponExpiryDateTime}</FormHelperText>

                  </div>
                </div>
                <div className="discount-form-field">
                  <div className="discount-field-name">Products: </div>
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    type="text" id="address-landmark"
                    name="products"
                    className="discount-text-field"
                    placeholder=" "
                    value={formik.values.products}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.products && Boolean(formik.errors.products)
                    }
                    helperText={formik.touched.products && formik.errors.products}
                  />
                </div>
                <div className="discount-form-field">
                  <div className="discount-field-name">Minimum Amount: </div>
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    type="text"
                    id="address-contact"
                    name="minAmountCart"
                    className="discount-text-field"
                    placeholder=" "
                    value={formik.values.minAmountCart}
                    onChange={formik.handleChange}
                    error={formik.touched.minAmountCart && Boolean(formik.errors.minAmountCart)}
                    helperText={formik.touched.minAmountCart && formik.errors.minAmountCart}
                  />
                </div>
                <div className="discount-form-field">
                  <div className="discount-field-name">Message to be displayed: </div>
                  <div className="discount-message">
                    <textarea
                      variant="outlined"
                      type="text"
                      autoComplete="off"
                      name="message"
                      id="address-contact"
                      className="discount-message-field"
                      placeholder="Enter your message "
                      value={formik.values.message}
                      onChange={formik.handleChange}
                    />
                    <FormHelperText>{formik.touched.message && formik.errors.message}</FormHelperText>
                  </div>
                </div>
                <div className="discount-form-field">
                  <div className="discount-field-name">Maximum usage per User: </div>
                  <TextField
                    variant="outlined"
                    autoComplete="off"
                    fullWidth
                    type="text"
                    id="address-contact"
                    name="maximumUse"
                    className="discount-text-field"
                    placeholder=" "
                    value={formik.values.maximumUse}
                    onChange={formik.handleChange}
                    error={formik.touched.maximumUse && Boolean(formik.errors.maximumUse)}
                    helperText={formik.touched.maximumUse && formik.errors.maximumUse}
                  />
                </div>
                <div className="discount-button">
                  <input
                    style={{cursor:"pointer"}}
                    type="submit"
                    className="add-coupon-button"
                    value="Add Coupon"
                  />
                </div>
              </form>

            </div>

          )}

          <table className="SellerDiscount-table">
            <thead className="seller-discount-thead">

              <th>Coupon Name</th>
              <th>Coupon Code</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Action</th>

            </thead>
          </table>
          <div>
            {sellerDiscountData?.result
              ?.filter((cardProps) => {
                if (status === 10) {
                  return true;
                } else if (status === 20 && cardProps.status === "Scheduled") {
                  return true;
                } else if (status === 30 && cardProps.status === "Active") {
                  return true;
                } else if (status === 40 && cardProps.status === "Expired") {
                  return true;
                } else {
                  return false;
                }
              })
              .map((cardProps) => (
                <AdminDiscountCard
                  key={cardProps.id}
                  {...cardProps}
                  updated={updated}
                  setUpdated={setUpdated}
                // onDelete={handleDelete}
                />
              ))}
          </div>
        </div>
      </div>

    </AdminLayoutAdjustment>
  );
}

export default withAdminAuth(AdminDiscount);
