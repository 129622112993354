import React, { useEffect, useRef } from "react";
import "../styles/CustomerProfilePage.css";
import "../styles/Contact.css";
import { UserCircle, Wallet } from "@phosphor-icons/react";
import { useState } from "react";
import { CaretRight } from "@phosphor-icons/react";
import {
  Heart,
  PhoneCall,
  Truck,
  ChatsCircle,
  AddressBook,
  SignOut,
} from "@phosphor-icons/react";
// import * as yup from "yup";
import { API_BASE_URL } from "../App";

import "../styles/ManageProfile.css";
import { useContext } from "react";
import BuyersHelp from "../components/BuyersHelp";
import MyOrders from "./MyOrders";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { CustomerContext } from "../context/CustomerContext";
// import EmptyPage from './EmptyPage'
import { Skeleton } from "@mui/material";
import CustomerFaq from "./CustomerFaq";
import { useNavigate } from "react-router-dom";
import Address from "./Address";
import CustomerWishlistCard from "../components/CustomerWishlistCard";
import CustomerLayout from "../components/CustomerLayout";
import CustomerLoginModal from "../components/CustomerLoginModal";
import Profile from "../components/Profile";
import ToastComponent from "../components/ToastComponent";
import EmptyPage from "./EmptyPage";
import CustomerWallet from "./CustomerWallet";
function CustomerProfilePage(props) {
  const navigate = useNavigate();
  const [wishlistLoading, setWishlistLoading] = useState(false);
  const isAuth = () => {
    if (customerData?.result?.userID) {
      return true;
    }
    return false;
  };

  const toastRef = useRef();

  const {
    accessToken,
    customerData,
    currentProfileTab,
    handleAuthExpiry,
    logout,
  } = useContext(CustomerContext);

  const [tab, setTab] = useState(currentProfileTab);
  // const [userData, setUserData] = useState({
  //     "userID": "123456789",
  //     "email": "anand@gmail.com",
  //     "salt": "string",
  //     // "password": "string",
  //     "mobileNumber": "9110685998",
  //     "firstName": "Sachidananda",
  //     "lastName": "MG",
  //     "typeOfUser": "BYR",
  //     "dateOfBirth": "2023-04-10T10:21:03.726Z"
  // })

  // const [productData, setProductData] = useState([
  //     {
  //         img: '',
  //         title: 'Jooties',
  //         category: 'AAAAAAAAA',
  //         price: '600',
  //         rating: '4.5',
  //         reviews: '20',
  //         quantity: '5',
  //         id: '1234'
  //     },
  //     {
  //         img: '',
  //         title: 'Jooties',
  //         category: 'BBBBBB',
  //         price: '600',
  //         rating: '4.5',
  //         reviews: '20',
  //         quantity: '5',
  //         id: '1234'
  //     },
  //     {
  //         img: '',
  //         title: 'Jooties',
  //         category: 'CCCCCCCCC',
  //         price: '600',
  //         rating: '4.5',
  //         reviews: '20',
  //         quantity: '5',
  //         id: '1234'
  //     },
  //     {
  //         img: '',
  //         title: 'Jooties',
  //         category: 'DDDDDDDDD',
  //         price: '600',
  //         rating: '4.5',
  //         reviews: '20',
  //         quantity: '5',
  //         id: '1234'
  //     },
  //     {
  //         img: '',
  //         title: 'Jooties',
  //         category: 'EEEEEEEE',
  //         price: '600',
  //         rating: '4.5',
  //         reviews: '20',
  //         quantity: '5',
  //         id: '1234'
  //     },
  //     {
  //         img: '',
  //         title: 'Jooties',
  //         category: 'FFFFFFF',
  //         price: '600',
  //         rating: '4.5',
  //         reviews: '20',
  //         quantity: '5',
  //         id: '1234'
  //     },
  //     {
  //         img: '',
  //         title: 'Jooties',
  //         category: 'GGGGGGGGGG',
  //         price: '600',
  //         rating: '4.5',
  //         reviews: '20',
  //         quantity: '5',
  //         id: '1234'
  //     },
  // ]);

  const [wishlistData, setWishlistData] = useState(null);

  // const fetchWishlistData = async () => {
    
  //   setWishlistLoading(true);
  //   try {
  //     const response = await fetch(
  //       `${API_BASE_URL}/favorites/api/v1/list?userId=${customerData?.result?.userID}`,
  //       {
  //         method: "GET",
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       }
  //     );

  //     const data = await response.json();
  //     console.log('Fetched wishlist data:', data);
  //     const handleAuthError = handleAuthExpiry(response);
  //     if (handleAuthError === true) {
  //       logout();
  //       return;
  //     }
  //     if (data?.result) {
  //       setWishlistData(data.result);
  //       setWishlistLoading(false);
  //     }
  //   } catch (error) {
  //     setWishlistLoading(false);
  //     console.error("Error fetching wishlist data:", error);
  //   }
  // };
  const fetchWishlistData = async () => {
    setWishlistLoading(true);
    try {
        if (!customerData?.result?.userID) {
            console.error("User ID is undefined");
            return;
        }

        const response = await fetch(
            `${API_BASE_URL}/favorites/api/v1/list?userId=${customerData.result.userID}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        const data = await response.json();
        console.log('Fetched wishlist data:', data);

        const handleAuthError = handleAuthExpiry(response);
        if (handleAuthError === true) {
            logout();
            return;
        }

        if (data?.result) {
            setWishlistData(data.result);
        }
    } catch (error) {
        console.error("Error fetching wishlist data:", error);
    } finally {
        setWishlistLoading(false);
    }
};
  useEffect(() => {
    fetchWishlistData();
  }, []);

  function filterWishlistData(id) {
    setWishlistData(
      wishlistData.filter((wishlistItem) => wishlistItem.ID !== id)
    );
  }

  useEffect(() => {
    //console.log(currentProfileTab)
    setTab(currentProfileTab);
  }, [currentProfileTab]);

  const [loginModalOpen, setLoginModalOpen] = React.useState(false);

  return (
    <>
      <ToastComponent ref={toastRef} />
      <CustomerLoginModal
        isOpen={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      />
      <CustomerLayout>
        {!isAuth() ? (
          <div className="customer-login-alert-component">
            <div className="customer-login-alert-component-head">
              Please Login to Access
            </div>
          </div>
        ) : (
          <div className="customer-profile-container">
            <div className="customer-profile-container-tabs">
              <div
                className={`customer-profile-container-tab ${
                  tab === "account" ? "tab-background" : ""
                }`}
                onClick={() => {
                  setTab("account");
                }}
              >
                <div style={{ display: "flex" }}>
                  <UserCircle
                    style={{
                      marginRight: "1rem",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <div className={`customer-profile-container-tab-name `}>
                    My Profile{" "}
                  </div>
                </div>
                {tab === "account" && <CaretRight style={{ float: "right" }} />}
              </div>
              <div
                className={`customer-profile-container-tab ${
                  tab === "orders" ? "tab-background" : ""
                }`}
                onClick={() => {
                  setTab("orders");
                }}
              >
                <div style={{ display: "flex" }}>
                  <Truck
                    style={{
                      marginRight: "1rem",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <div className="customer-profile-container-tab-name">
                    My Orders
                  </div>
                </div>
                {tab === "orders" && <CaretRight style={{ float: "right" }} />}
              </div>
              <div
                className={`customer-profile-container-tab ${
                  tab === "wishlist" ? "tab-background" : ""
                }`}
                onClick={() => {
                  setTab("wishlist");
                }}
              >
                <div style={{ display: "flex" }}>
                  <Heart
                    style={{
                      marginRight: "1rem",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <div className="customer-profile-container-tab-name">
                    My Wishlist
                  </div>
                </div>
                {tab === "wishlist" && (
                  <CaretRight style={{ float: "right" }} />
                )}
              </div>
              <div
                className={`customer-profile-container-tab ${
                  tab === "help" ? "tab-background" : ""
                }`}
                onClick={() => {
                  setTab("help");
                }}
              >
                <div style={{ display: "flex" }}>
                  <PhoneCall
                    style={{
                      marginRight: "1rem",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <div className="customer-profile-container-tab-name">
                    Contact Us
                  </div>
                </div>
                {tab === "help" && <CaretRight style={{ float: "right" }} />}
              </div>
              <div
                className={`customer-profile-container-tab ${
                  tab === "wallet" ? "tab-background" : ""
                }`}
                onClick={() => {
                  setTab("wallet");
                }}
              >
                <div style={{ display: "flex" }}>
                  <Wallet
                    style={{
                      marginRight: "1rem",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <div className="customer-profile-container-tab-name">
                    Wallet
                  </div>
                </div>
                {tab === "wallet" && <CaretRight style={{ float: "right" }} />}
              </div>
              <div
                className={`customer-profile-container-tab ${
                  tab === "faq" ? "tab-background" : ""
                }`}
                onClick={() => {
                  setTab("faq");
                }}
              >
                <div style={{ display: "flex" }}>
                  <ChatsCircle
                    style={{
                      marginRight: "1rem",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <div className="customer-profile-container-tab-name">FAQ</div>
                </div>
                {tab === "faq" && <CaretRight style={{ float: "right" }} />}
              </div>
              <div
                className={`customer-profile-container-tab ${
                  tab === "address" ? "tab-background" : ""
                }`}
                onClick={() => {
                  setTab("address");
                }}
              >
                <div style={{ display: "flex" }}>
                  <AddressBook
                    style={{
                      marginRight: "1rem",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <div className="customer-profile-container-tab-name">
                    Address
                  </div>
                </div>
                {tab === "address" && <CaretRight style={{ float: "right" }} />}
              </div>
              <div
                className={`customer-profile-container-tab ${
                  tab === "LogOut" ? "tab-background" : ""
                }`}
                onClick={logout}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <AddressBook style={{ marginRight: '1rem', width: '24px', height: '24px' }} /> */}
                  {/* <div style={{display:"flex",justifyContent:"center",alignItems:"center",gap:"10px"}} > */}
                  <SignOut
                    style={{
                      marginRight: "1rem",
                      width: "24px",
                      height: "24px",
                    }}
                  />
                  <div className="customer-profile-container-tab-name">
                    LogOut
                  </div>
                  {/* </div> */}
                </div>
                {tab === "LogOut" && <CaretRight style={{ float: "right" }} />}
              </div>
            </div>
            <div className="customer-profile-container-tab-panel">
              {tab === "account" && (
                <div>
                  {/* <div className='profile-page-head'>My Profile</div> */}

                  <Profile />
                </div>
              )}
              {tab === "orders" && (
                <div>
                  <MyOrders />
                </div>
              )}
              {tab === "wishlist" && (
                <div>
                  <div className="profile-page-head">My Wishlist</div>

                  {wishlistLoading ? (
                    <div className="customer-wishlist-cards">
                      <CustomerWishlistCardShimmer />
                      <CustomerWishlistCardShimmer />
                      <CustomerWishlistCardShimmer />
                      <CustomerWishlistCardShimmer />
                      <CustomerWishlistCardShimmer />
                      <CustomerWishlistCardShimmer />
                    </div>
                  ) : wishlistData?.length === 0 ? (
                    <div>
                      <EmptyPage desc='Updates on the way'/>
                    </div>
                  ) : (
                    <div className="customer-wishlist-cards">
                      {wishlistData?.map((wishlist) => (
                        <CustomerWishlistCard
                          {...wishlist}
                          filterWishlistData={filterWishlistData}
                          onClick={() => {
                            navigate(`/productDetail/${wishlist.productId}`);
                          }}
                        />
                      ))}
                    </div>
                  )}
                </div>
              )}

              {tab === "help" && <BuyersHelp />}
              {tab === "faq" && (
                <div>
                  <div className="profile-page-head">
                    Frequently Asked Questions
                  </div>

                  <CustomerFaq />
                </div>
              )}
              {tab === "wallet" && (
                <div>
                  <div className="profile-page-head">Myhraki Pay Gift Card</div>
                  <CustomerWallet />
                </div>
              )}
              {tab === "address" && <Address />}
              {tab === "LogOut" && (
                <div>
                  <button className="nav-btn" onClick={logout}>
                    Logout
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </CustomerLayout>
    </>
  );
}

export default CustomerProfilePage;
const CustomerWishlistCardShimmer = () => {
  return (
    <div
      className="Customer_wishlist_card_container"
      style={{
        border: "1px solid rgba(217,217,217,1)",
        borderRadius: "8px",
        padding: "7px",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        gap: "0px",
        height: "300px",
      }}
    >
      {/* <div className='customer-product-card-img'>
          <Skeleton variant="rectangular" width='100%' height={200} borderRadius="15px"/>
          <div className='customer-product-card-fav'>
            <HighlightOffIcon size={32} weight="fill" color="#f70808" />
          </div>
        </div> */}
      <div className="customer-product-card-img">
        <Skeleton
          variant="rectangular"
          width="100%"
          height="100%"
          borderRadius="15px"
        />
      </div>
      <Skeleton
        variant="text"
        className="customer-product-card-title"
        sx={{ fontSize: "1.25rem" }}
        width="80%"
        borderRadius="15px"
        height={25}
      />
      {/* <Skeleton
        variant="text"
        className="customer-product-card-category"
        sx={{ fontSize: "1rem" }}
        width="50%"
        height={10}
      /> */}
      <Skeleton
        variant="rectangular"
        className="customer-product-card-price"
        sx={{ fontSize: "1rem" }}
        width="80%"
        borderRadius="15px"
        height={10}
      />
      <Skeleton
        variant="rectangular"
        className="customer-product-card-review"
        sx={{ fontSize: "1rem" }}
        width="80%"
        borderRadius="15px"
        height={10}
      />
      <Skeleton
        variant="rectangular"
        className="customer-product-card-quantity"
        sx={{ fontSize: "1rem" }}
        width="50%"
        borderRadius="15px"
        height={10}
      />
      <Skeleton
        variant="rectangular"
        className="customer-product-card-title"
        width="100%"
        borderRadius="15px"
        height={15}
      />
    </div>
  );
};
