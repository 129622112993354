// import { Code } from "@mui/icons-material";
import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { errorAlert } from "../components/Alert";
// import { successAlert } from "../components/Alert";
import { API_BASE_URL } from "../App";

export const CustomerContext = createContext();

export const CustomerContextProvider = ({ children }) => {
  const [phoneNumberForOtp, setPhoneNumberForOtp] = useState('');
  const [accessToken, setAccessToken] = useState();
  const [buyerData, setBuyerData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  // const [cartData, setCartData] = useState();
  const [currentProfileTab, setCurrentProfileTab] = useState('account')
  // const location = useLocation();
  const [selectedCategory, setSelectedCategory] = useState();
  const [searchText, setSearchText] = useState();
  const [selectedPriceRange, setSelectedPriceRange] = useState();
  const [selectedListItemIndex, setSelectedListItemIndex] = useState();
  const [selectedOrder, setSelectedOrder] = useState();
  const [currentOrderDetail, setCurrentOrderDetail] = useState();
  const [cart, setCart] = useState(0);
  const navigate = useNavigate();




  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAccessToken(token);
      fetchData(token);
      
    }
  }, []);

  const storedPathname = localStorage.getItem('currentPage');

  const handleAuthExpiry = (response)=>{
    if(response?.status===401){
      return true
    }else{
      return false
    }
  }

  const fetchCartData = async (customerData, token) => {
    if(!customerData){
      return;
    }
    try {
      //console.log(customerData?.result?.userID)
      const response = await fetch(
        `${API_BASE_URL}/cart/api/v1/getbybuyerID?buyerID=${customerData}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
    
      if (Array.isArray(data?.result)) {
        // console.log("cartdata", data);
        // console.log(data?.result?.length());

        setCart(data?.result?.length);
        // console.log(data?.result);
      } 
    } catch (e) {
      // console.log(e);
    }
  };

  const fetchData = async (accessToken) => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/info`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      setCustomerData(data);
      //   if (data?.result?.userID) {
      //     navigate('/customerProfile');
      //   }else{
      //     navigate('/');
      //   }
      setBuyerData(data);
      fetchCartData(data?.result?.userID, accessToken);
      return data;
    } catch (error) {
      // Handle error
      //   successAlert('','No')


    }
  };

  const handleAuthCheck=(response)=>{
    if(response?.status===401){
      errorAlert('','Session timed out. ')
      logout();
    }
    else{
      return;
    }
  }

  const login = async (email, password) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: email,
      password: password,
      mobileNumber: "",
      typeOfUser: 2,
      otp: "",
      isOTPBasedLogin: false,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/login`,
        requestOptions
      );
      const result = await response.json();
      if (!result.result?.accessToken) {
        // Handle error
        return { status: 400 }
      }
      localStorage.setItem("token", result.result.accessToken);
      setAccessToken(result.result.accessToken);
      fetchData(result.result.accessToken);
      return result;
    } catch (error) {
      // Handle error
      return { status: 500 };
    }
  };

  const loginWithOtp = async (phone, otp) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: '',
      password: '',
      mobileNumber: phone,
      typeOfUser: 2,
      otp: otp,
      isOTPBasedLogin: true,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `${API_BASE_URL}/users/api/v1/login`,
        requestOptions
      );
      const result = await response.json();
      if (!result.result?.accessToken) {
        return;
      }
      localStorage.setItem("token", result.result.accessToken);
      setAccessToken(result.result.accessToken);
      fetchData(result.result.accessToken);
      return result;
    } catch (error) {
      // Handle error
    }
  };

  const loginWithOtpEmail = async (email, otp) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      email: email,
      password: '',
      mobileNumber: '',
      typeOfUser: 2,
      otp: otp,
      isOTPBasedLogin: true,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(`${API_BASE_URL}/users/api/v1/OTPVerify?email=${email}&userTypeId=2&OTP=${otp} `);
      const result = await response.json();
      if (!result.result ===true) {
        return;
      }
      // localStorage.setItem("token", result.result.accessToken);
      // setAccessToken(result.result.accessToken);
      // fetchData(result.result.accessToken);
      return result;
    } catch (error) {
      // Handle error
    }
  };

  const verifyPhoneOtp = async (number, code) => {
    try {
      const response = await fetch(`${API_BASE_URL}/users/api/v1/OTPVerify?mobileNumber=${number}&userTypeId=2&OTP=${code}
      `);
      const json = await response.json();
      //console.log(json);
      return json;
    } catch (e) {

    }
  }
  const verifyEmailOtp = async (email, code) => {
    try {
      const response = await fetch(`${API_BASE_URL}/users/api/v1/OTPVerify?email=${email}&userTypeId=2&OTP=${code}
      `);
      const json = await response.json();
      //console.log(json);
      if (response.result) {
        return true;
      }
      return false;
    } catch (e) {

    }
  }

  const logout = () => {
    setCustomerData(null);
    navigate("/");
    localStorage.setItem("token", null);
  };

  return (
    <CustomerContext.Provider value={{
      handleAuthCheck,
      selectedCategory,
      setSelectedPriceRange,
      selectedPriceRange,
      setSelectedOrder,
      selectedOrder, cart, setCart,
      currentOrderDetail, setCurrentOrderDetail,handleAuthExpiry,
      setSelectedCategory, searchText, setSearchText , customerData, setCurrentProfileTab, currentProfileTab, phoneNumberForOtp, fetchData, verifyPhoneOtp, verifyEmailOtp, loginWithOtpEmail, setPhoneNumberForOtp, loginWithOtp, login, logout, accessToken, selectedListItemIndex, setSelectedListItemIndex
    }}>
      {children} 
    </CustomerContext.Provider>
  );
};
