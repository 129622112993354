import logo from "../Images/logo.png";
import {useContext, useState} from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import "../styles/InvitedSeller.css"
import { TroubleshootSharp } from "@mui/icons-material";
import { API_BASE_URL } from "../App";
import { CustomerContext } from "../context/CustomerContext";
import { useEffect } from "react";

function InviteSeller(props) {

  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const { customerData, accessToken, setSelectedOrder, handleAuthCheck ,selectedOrder} = useContext(CustomerContext);
  const [invoiceData, setInvoiceData] = useState(props.invoiceData);
  // const grandTotal = invoiceData.reduce(
  //   (acc, row) => acc + row.itemQuantity * row.itemCost * (1 + row.totalGST),
  //   0
  // );
  const grandTotal = invoiceData.reduce(
    (acc, row) => acc + (parseInt(row.itemQuantity) * parseInt(row.itemCost) ) + 100 +(parseInt(row.itemQuantity)*(parseInt(row.itemCost)*((row.totalGST)/100))),
    0
  );

  // const downloadPDF = () =>{
  //   const capture = document.querySelector('.actual-receipt');
  //   setLoader(true);
  //   html2canvas(capture,{ scale: 2, quality: 0.8 }).then((canvas)=>{
  //     const imgData = canvas.toDataURL('img/jpeg' ,0.9);
  //     const doc = new jsPDF('p', 'pt', 'a4', TroubleshootSharp ,true);
  //     const componentWidth = doc.internal.pageSize.getWidth();
  //     const componentHeight = doc.internal.pageSize.getHeight();
  //     doc.addImage(imgData, 'JPEG', 0, 0, componentWidth, componentHeight);
  //     setLoader(false);
  //     doc.save('receipt.pdf');
  //   })
  // }
  const downloadPDF = () => {
    const capture = document.querySelector('.actual-receipt');
    setLoader(true);
  
    // Adjust these values to control the PDF size and clarity
    const scale = 2; // Lower scale can reduce file size
    const quality = 1; // Lower quality can reduce file size
  
    html2canvas(capture, { scale, logging: false }).then((canvas) => {
      const imgData = canvas.toDataURL('image/jpeg', quality);
      const pdfWidth = canvas.width / 2.0; // Adjusted width for better clarity
      const pdfHeight = canvas.height / 2.0; // Adjusted height for better clarity
  
      const doc = new jsPDF('p', 'pt', [pdfWidth, pdfHeight], true);
      doc.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
      setLoader(false);
      doc.save('receipt.pdf');
    });
  };
  
  
  function removeLastHyphenAndAfter(input) {
    var lastHyphenIndex = input.lastIndexOf("-");
    if (lastHyphenIndex >= 0) {
      return input.substring(0, lastHyphenIndex);
    }
    return input;
  }


  return (
   
    <div className="invoice actual-receipt">
    <header>
      <h1 className='heading-invoice'>Tax Invoice</h1>
      {invoiceData.length > 0 && (
        <div>
          {/* <h2>Shipped To: <span>{invoiceData[0].buyerName}</span></h2> */}
          <p>
            <h2>Shipped From :</h2>
            <span>224, 7th Cross Rd, Indira Nagar II Stage, 1st Stage, Indiranagar, Bengaluru, Karnataka 560038</span>
          </p>
          <h2>GSTIN :</h2>  <span>{invoiceData[0]?.GST}</span>
        </div>
      )}
    </header>
    <hr className="separator" />
    <section className="invoice-section">
      <div className='Oder-id-filed-set'>
        <div className='Oder-id-filed'>
          <div className="order-details">
            <div><h3>Order ID:</h3></div>
            <div>{invoiceData.length > 0 ? invoiceData[0]?.orderNumber : ''}</div>
          </div>  
          <div>
            <div className="order-details"><h3>Order Date:</h3></div>
            <div>{invoiceData.length > 0 ? invoiceData[0]?.date : ''}</div>
          </div>
          <div className="order-details"><h3>PAN:</h3></div>
          <div>{invoiceData.length > 0 ? invoiceData[0]?.pan : ''}</div>
        </div>
        <div className='Oder-id-filed'>
          <div className='title-invoice'>
            <h2>Bill To</h2>
            <p>{invoiceData.length > 0 ? invoiceData[0]?.buyerName : ''}</p>
          </div>
          <div className='description-invoice'>
            <p>{invoiceData.length > 0 ? invoiceData[0]?.buyerBilling : ''}</p>
          </div>
        </div>
        <div className='Oder-id-filed'>
          <div className='title-invoice'>
            <h2>Ship To</h2>
            <p>{invoiceData.length > 0 ? invoiceData[0]?.buyerName : ''}</p>
          </div>
          <div className='description-invoice'>
            <p>{invoiceData.length > 0 ? invoiceData[0]?.buyerDestination : ''}</p>
          </div>
        </div>
      </div>
    </section>
    <section className="invoice-section">
      <div>
        <div className="product-details">
          <table className='custom-invoice-table'>
            <thead className='invoice-header-row'>
              <td className='invoice-header-cell'>Title</td>
              <td className='invoice-header-cell'>Qty</td>
              <td className='invoice-header-cell'>Taxable Value ₹</td>
              <td className='invoice-header-cell'>GST ₹</td>
              <td className='invoice-header-cell'>Shipping ₹</td>
              <td className='invoice-header-cell'> Total ₹</td>
            </thead>
            <tbody className='invoice-data-row'>
              {invoiceData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td className='invoice-data-cell'>{(row.itemTitle)}</td>
                  <td className='invoice-data-cell'>{parseInt(row.itemQuantity)}</td>
                  <td className='invoice-data-cell'>{parseInt(row.itemCost)}</td>
                  <td className='invoice-data-cell'>{parseInt(row.itemCost)*((row.totalGST)/100)}</td>
                  <td className='invoice-data-cell'>{'100'}</td>
                  <td className='invoice-data-cell'>{(parseInt(row.itemQuantity) * parseInt(row.itemCost) ) + 100 +(parseInt(row.itemQuantity)*(parseInt(row.itemCost)*((row.totalGST)/100)))}</td>
                  {/* <td className='invoice-data-cell'>{(parseInt(row.itemQuantity) * parseInt(row.itemCost) )+(parseInt(row.itemcost)*parseInt(row.itemQuantity)*parseInt(row.totalGST))+100}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
    <section className="invoice-section">
      <div className='Grand-total-field'>
        <div className="grand-total">
        {/* {invoiceData.reduce((acc, cur) => acc + cur.totalCost, 0)} */}
          <h2 style={{fontSize:'16px'}}><strong>Grand Total: ₹ {grandTotal.toFixed(2)}</strong></h2>
        </div>
      </div>
      <div className="additional-info">
        <p>Authorized Signatory</p>
      </div>
    </section>
    <hr className="separator" />
    <footer>
      <section className="invoice-section">
        <div className='logo-field'>
          <a href="https://myhraki.com/" target="_blank">
            <img loading="lazy" src={logo} alt="" className="Main-Logo-invoice" />
          </a>
        </div>
        <div className='footer-invoice'>
          <div className="returns-policy">
            <p className='para'>
              <span className='invoice-span'>Returns Policy:</span>  We offer a hassle-free 5-day return window from the date of purchase. Items must be unused, in original packaging, and accompanied by proof of purchase for a full refund. Please contact our support team to initiate the return process.
              Make sure you take a video of the unboxing to help us provide you a better service.
            </p>
            <p  className='para'>224, 7th Cross Rd, Indira Nagar II Stage, 1st Stage, Indiranagar, Bengaluru, Karnataka 560038</p>
            <p  className='para'>Contact Myhraki: 1800 203 1217 || <a href="https://myhraki.com/" target="_blank" rel="noopener noreferrer">www.myhraki.com</a></p>
          </div>  
        </div>
      </section>
    </footer>
    <hr className="separator" />
    <div className="receipt-actions-div">
            <div className="actions-right">
              <button
                className="receipt-modal-download-button"
                onClick={downloadPDF}
                disabled={!(loader===false)}
              >
                {loader?(
                  <span>Downloading</span>
                ):(
                  <span>Download</span>
                )}

              </button> 
            </div>
          </div>
  </div>
  
  );
}

export default InviteSeller;