import React, { useEffect, useMemo, useRef } from "react";
import CustomerNavbar from "../components/CustomerNavbar";
import CustomerCategoryBar from "../components/CustomerCategoryBar";
import CustomerHeading from "../components/CustomerHeading";
import "../styles/ProductListing.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomerProductCard from "../components/CustomerProductCard";
import { useState } from "react";
import { SortAscending } from "@phosphor-icons/react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import CustomerLayout from "../components/CustomerLayout";
import FormControl from "@mui/material/FormControl";
import { useContext } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CircularProgress, Skeleton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { CustomerContext } from "../context/CustomerContext";
import CustBottomNav from "../components/CustBottomNav";
import { API_BASE_URL } from "../App";
import { useFilterContext } from "../context/FilterContext";
import HorizontalScroller from "../components/HorizontalScroller";
import "../styles/CustomerLandingPage.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SliderArrowLeft from "../Images/SliderArrowLeft.png";
import SliderArrowRight from "../Images/SliderArrowRight.png";
import EmptyPage from "./EmptyPage";

function CustomerProductListingPage(props) {
  const [pageNo, setPageNo] = useState(0);
  const [NewArrivalData, setNewArrivalData] = useState([]);
  const [newLoading, setNewLoading] = useState(false);
  const [stopFetching, setStopFetching] = useState(false);
  const loader = useRef(null);
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  const pageSize = 20;
  const { selectedPriceRange, selectedCategory, setApiUrl } =
    useContext(CustomerContext);
  const {
    products,
    productList,
    setProductList,
    loading,
    setLoading,
    pageNumber,
    setPageNumber,
    updateFilters,
    fetchProductData,
    filters,
    newproductList,
    setNewProductList,
    scrollEnd,
    setScrollEnd,
  } = useFilterContext();
  // const [loading, setLoading] = useState(false);
  // const [productData, setProductData] = useState([]);
  // console.log(products);
  // console.log(productList);

  const [categoryData, setCategoryData] = useState([]);
  const productsStartRef = useRef(null);

  async function fetchCategoryData() {
    try {
      const response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/category/All`,
        {
          method: "GET",
          headers: {},
        }
      );
      const data = await response.json();
      setCategoryData(data?.result);
    } catch {
      console.log("Error");
    }
  }

  async function fetchNewArrivalData() {
    setNewLoading(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/productgen/api/v1/search?NewArrival=true&PageNumber=${pageNo}&PageSize=${pageSize}&IncludeCount=${true}`,
        {
          method: "GET",
          headers: {},
        }
      );
      const data = await response.json();
      setNewArrivalData(data?.result?.result);
      setNewLoading(false);
    } catch {
      //console.log('Error')
      setNewLoading(false);
    }
  }

  
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "20px",
      threshold: 1,
    };

    const observer = new IntersectionObserver(handleObserver, options);

    if (loader.current) {
      observer.observe(loader.current);
      
    }
    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [fetchProductData, pageNumber, selectedCategory]);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && !loading) {
      console.log("Page Increased");
      const nextPageNumber = pageNumber + 1;
      setPageNumber(nextPageNumber);
    }
  };

  useEffect(() => {
    fetchCategoryData();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchNewArrivalData();
  }, [selectedCategory]);

  // const handlePageChange = (event, value) => {
  //   window.scrollTo(0, 0);
  //   // setPageNumber(value===1?0:(value-1));
  //   setPageNumber(value === 0 ? 1 : value);
  // };

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <img
      loading="lazy"
      src={SliderArrowRight}
      alt="prevArrow"
      {...props}
      style={{ width: "30px", height: "30px" }}
    />
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <img
      loading="lazy"
      src={SliderArrowLeft}
      alt="nextArrow"
      {...props}
      style={{ width: "30px", height: "30px" }}
    />
  );
  var settingsForProductCard = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },

      {
        breakpoint: 1054,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1104,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
    ],
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
  };

  const handleClearAllClick = () => {
    updateFilters({
      searchValue: "",
      category: "",
      priceRange: "",
    });
    setPageNumber(0);
    setScrollEnd(true);
    setProductList([]);
    fetchProductData("", "", "", false);
  };

  return (
    <CustomerLayout>
      <div style={{ margin: "0 auto", width: "95%" }}>
        <CustomerHeading text="All Products" />
        <div className="product-listing">
          <div className="product-listing-filter">
            <div className="product-listing-filter-section">
              <div className="product-listing-filter-section-heading">
                <SortAscending
                  style={{ color: "#FD2A80", width: "24px", height: "24px" }}
                />
                Filters
              </div>
              <div
                className="product-listing-filter-section-clear-all"
                onClick={handleClearAllClick}
              >
                Clear All
              </div>
            </div>
            <BestSellerFilter
              categoryData={categoryData}
              productData={productList}
            />
          </div>
          <div className="product-listing-products">
            {!loading && productList?.length > 0
              ? productList.map((product) => (
                  <CustomerProductCard key={product.id} {...product} />
                ))
              : !loading && <EmptyPage desc="Updates on the way" />}
            {loading && <ProductFilterShimmer />}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            position: "relative",
            scrollBehavior: "smooth",
          }}
        >
          {scrollEnd ? (
            <div ref={loader} style={{ position: "absolute" }}>
              <CircularProgress style={{ margin: "0px auto", color: "pink" }} />
            </div>
          ) : // <Stack
          //   spacing={2}
          //   style={{
          //     display: "flex",
          //     justifyContent: "center",
          //     margin: "1rem auto",
          //     alignItems: "center",
          //   }} productList?.length > 10 && !newproductList &&
          // >
          //   <Pagination
          //     count={10}
          //     page={pageNumber}
          //     onChange={handlePageChange}
          //   />
          // </Stack>
          !scrollEnd ? (
            <div
              style={{
                fontSize: "20px",
                color: "#fe3c72",
                fontWeight: "600px",
              }}
            >
              End of Scrolling
            </div>
          ) : scrollEnd && loading ? (
            <div></div>
          ) : (
            <div></div>
          )}
        </div>

        <CustomerHeading text="New Arrivals " nav="/products" />
        <div className="customer-landing-page-section-container">
          <Slider {...settingsForProductCard}>
            {newLoading
              ? [1, 2, 3, 4]?.map((i) => (
                  <div key={i}>
                    <CustomerWishlistCardShimmer />
                  </div>
                ))
              : NewArrivalData?.map((product) => (
                  <div key={product.productId}>
                    <CustomerProductCard
                      {...product}
                      onClick={() => {
                        navigate(`/productDetail/${product.productId}`);
                        window.scrollTo(0, 0);
                      }}
                    />
                  </div>
                ))}
          </Slider>
        </div>
        {/* <CustomerHeading text='Most Viewed'  nav='/products' />
            <div className='customer-landing-page-section-container'>
            <Slider {...settingsForProductCard}>
                    {newLoading ? (
                        [1, 2, 3, 4]?.map((i) => (
                            <div key={i}>
                                <CustomerWishlistCardShimmer

                                />
                            </div>
                        ))
                    ) : (
                        NewArrivalData?.map((product) => (
                            <div key={product.productId}>
                                <CustomerProductCard
                                    {...product}
                                    onClick={() => {
                                        navigate(`/productDetail/${product.productId}`);
                                        window.scrollTo(0, 0);
                                    }}
                                />
                            </div>
                        ))
                    )
                    }
                </Slider>
            </div> */}
      </div>
      <CustBottomNav />
    </CustomerLayout>
  );
}
export default CustomerProductListingPage;
function BestSellerFilter(props) {
  const [isOpen, setIsOpen] = useState(false);
  // const { setSelectedCategory, selectedCategory, loading } = useContext(CustomerContext);
  const {
    filters,
    updateFilters,
    fetchProductData,
    setFilters,
    pageNumber,
    setPageNumber,
    setScrollEnd,
    setLoading,
  } = useFilterContext();
  // const [pageNumber, setPageNumber] = useState(0);
  
  const pageSize = 20;
  const [selectedPriceRange, setSelectedPriceRange] = useState(0);
  const [categoryData, setCategoryData] = useState([]);

  const handleRadioChange = (event) => {
    const categoryId = event.target.value;
    // setSelectedCategory(categoryId);
    console.log(categoryId);
    setPageNumber(0);
    setScrollEnd(true);

    updateFilters({
      searchValue: filters.searchValue,
      category: categoryId,
      priceRange: filters.priceRange,
    });
    fetchProductData(
      filters.searchValue,
      categoryId,
      filters.priceRange,
      false
    );
  };

  const handlePriceRangeChange = (event) => {
    const selectedRange = event.target.value;
    setSelectedPriceRange(selectedRange);
    setPageNumber(0);
    
    setScrollEnd(true);
    updateFilters({
      searchValue: filters.searchValue,
      priceRange: selectedRange,
      category: filters.category,
    });
    console.log(filters);
    fetchProductData(
      filters.searchValue,
      filters.category,
      event.target.value,
      false
    );
  };
  useEffect(() => {
    updateFilters({
      searchValue: filters.searchValue,
      priceRange: 0,
      category: filters.category,
    });
  }, []);

  return (
    <div className="accordian">
      <Accordion
        sx={{ width: "250px", background: "#FFF2F6", marginBottom: "1rem" }}
        defaultExpanded={false}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            style={{
              fontSize: "16px",
              textTransform: "uppercase",
              color: "#000",
              textAlign: "left",
            }}
          >
            Categories
          </Typography>
        </AccordionSummary>
        <AccordionDetails className="customAccordionDetails">
          <FormControl component="fieldset">
            <RadioGroup
              aria-labelledby="demo-row-radio-buttons-group-label"
              value={filters.category}
              name="category"
              onChange={handleRadioChange}
            >
              {props.categoryData?.map((category) => (
                <FormControlLabel
                  key={category.categoryId}
                  value={category.categoryId}
                  control={<Radio style={{ color: "#FD2A80" }} />}
                  label={category.categoryName}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
      <Accordion
        sx={{ width: "250px", background: "#FFF2F6", marginBottom: "1rem" }}
        defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Price Range</Typography>
        </AccordionSummary>
        <AccordionDetails className="customAccordionDetails">
          <FormControl component="fieldset">
            <RadioGroup
              aria-labelledby="demo-row-radio-buttons-group-label"
              value={filters.priceRange}
              name="priceRange"
              onChange={handlePriceRangeChange}
            >
              <FormControlLabel
                value={500}
                control={<Radio style={{ color: "#FD2A80" }} />}
                label="Under Rs. 499"
              />
              <FormControlLabel
                value={1000}
                control={<Radio style={{ color: "#FD2A80" }} />}
                label="Under Rs. 999"
              />
              <FormControlLabel
                value={2000}
                control={<Radio style={{ color: "#FD2A80" }} />}
                label="Under Rs. 1,999"
              />
              <FormControlLabel
                value={3000}
                control={<Radio style={{ color: "#FD2A80" }} />}
                label="Under Rs. 2,999"
              />
              <FormControlLabel
                value={5000}
                control={<Radio style={{ color: "#FD2A80" }} />}
                label="Under Rs. 5,000"
              />
              <FormControlLabel
                value={10000}
                control={<Radio style={{ color: "#FD2A80" }} />}
                label="Under Rs. 10,000"
              />
              <FormControlLabel
                value={1000000000}
                control={<Radio style={{ color: "#FD2A80" }} />}
                label="All"
              />
            </RadioGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
const ProductFilterShimmer = () => {
  return (
    <div className="product-listing-products">
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
      <CustomerWishlistCardShimmer />
    </div>
  );
};

const CustomerWishlistCardShimmer = () => {
  return (
    <div
      className="customer-product-card"
      style={{
        border: "1px solid rgba(217,217,217,1)",
        borderRadius: "8px",
        padding: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "30%",
        height: "340px",
      }}
    >
      <div className="customer-product-card-img">
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          borderRadius="15px"
        />
        {/* <div className='customer-product-card-fav'>
                  <HighlightOffIcon size={32} weight="fill" color="#f70808" />
              </div> */}
      </div>
      {/* <Skeleton variant="text" className='customer-product-card-title' sx={{ fontSize: '1.25rem',marginTop:"30px"  }} width='80%' height={25} /> */}
      <Skeleton
        variant="text"
        className="customer-product-card-category"
        sx={{ fontSize: "20px", marginTop: "30px" }}
        width="80%"
        height={20}
      />
      {/* <Skeleton variant="text" className='customer-product-card-price' sx={{ fontSize: '18px' }} width='30%' height={20} /> */}
      <Skeleton
        variant="text"
        className="customer-product-card-review"
        sx={{ fontSize: "10px" }}
        width="30%"
        height={20}
      />
      <Skeleton
        variant="text"
        className="customer-product-card-quantity"
        sx={{ fontSize: "10px" }}
        width="50%"
        height={15}
      />
      <Skeleton
        variant="text"
        className="customer-product-card-title"
        width="100%"
        height={50}
      />
    </div>
  );
};
